<template>
  <v-row class="inner-content">
    <v-col>
      <MutationLogTable />
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'AdCustomizerMutationLog',

  components: {
    MutationLogTable: () => import('@/components/tables/MutationLogTable.vue')
  },
}
</script>
